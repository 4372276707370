import { parseFilePath } from '@/common/utils/parseFilePath'
import { loadImage } from './loadImage'

export function downloadLink(src: string, dest: string): void {
    const a = document.createElement('a')
    a.href = src
    a.download = dest
    a.click()
    a.remove()
}

export async function downloadRemoteLink(src: string, dest: string, mimeType: string): Promise<void> {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    const image = await loadImage(src)
    canvas.width = image.width
    canvas.height = image.height
    context?.drawImage(image, 0, 0)

    const img64 = canvas.toDataURL(mimeType)
    downloadLink(img64, dest)

    canvas.remove()
}

export function downloadRaws(fileUrls: Array<string>): void {
    // Get unique fileUrls (some templates reuse same image with different modifiers but we don't need the user to download same file twice)
    fileUrls = [...new Set(fileUrls)]

    for (let i = 0; i < fileUrls.length; i++) {
        const file = parseFilePath(fileUrls[i])
        const destFileName = `${file.name}.${file.extension}`

        if (fileUrls[i].startsWith('https://')) {
            void downloadRemoteLink(fileUrls[i], destFileName, file.mimeType)
        } else {
            void downloadLink(fileUrls[i], destFileName)
        }
    }
}
