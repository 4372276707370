import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container flex-vgap" }
const _hoisted_2 = { class: "meta flex-vgap" }
const _hoisted_3 = { class: "grid" }

import { computed } from 'vue'
import { APP_DESC, S3_SIZE_CARD } from '@/common/Constants'
import { getS3PublicUrlHologra } from '@/common/utils/getS3'
import { useMemeTemplateStore } from '@/web/client/store/MemeTemplate/useMemeTemplateStore'
import { downloadRaws } from '@/web/client/utils/download'
import { useSeoMeta } from '@unhead/vue'
import GridCard from './GridCard.vue'
import { RouteName } from '@/web/client/router/routes'


export default /*@__PURE__*/_defineComponent({
  __name: 'GridHolograVideo',
  props: {
    ytbVideoId: {}
  },
  setup(__props: any) {

const props = __props

const memeTemplateStore = useMemeTemplateStore()
const video = computed(() => memeTemplateStore.holograMap.get(props.ytbVideoId))

const downloadTemplate = (idx: number) => {
    const fileNames = [getS3PublicUrlHologra(props.ytbVideoId, idx)]
    downloadRaws(fileNames)
}

const title = computed(() => video.value?.title ?? 'Unknown Video')
const ogImage = computed(() => video.value?.cardUrl)
useSeoMeta({
    title,
    description: APP_DESC,
    ogImage,
    twitterCard: 'summary_large_image',
})

return (_ctx: any,_cache: any) => {
  const _component_ExternalLink = _resolveComponent("ExternalLink")!
  const _component_RelativeTime = _resolveComponent("RelativeTime")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, [
        _createVNode(_component_ExternalLink, {
          href: `https://www.youtube.com/watch?v=${_ctx.ytbVideoId}`,
          class: "header"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(title.value), 1)
          ]),
          _: 1
        }, 8, ["href"])
      ]),
      _createVNode(_component_RelativeTime, {
        datetime: video.value?.publishedAt,
        prefix: "Uploaded"
      }, null, 8, ["datetime"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((video.value?.numFrames ?? 0), (frameIdx) => {
        return (_openBlock(), _createBlock(GridCard, {
          key: `${_ctx.ytbVideoId}-${frameIdx}`,
          tooltip: `Create ${video.value?.title} Meme`,
          route: {
                    name: _unref(RouteName).MemeEditorHologra,
                    params: {
                        ytbVideoId: _ctx.ytbVideoId,
                        frameIdx,
                    },
                },
          image: _unref(getS3PublicUrlHologra)(_ctx.ytbVideoId, frameIdx, _unref(S3_SIZE_CARD)),
          "aspect-ratio": 1.618,
          onDownload: ($event: any) => (downloadTemplate(frameIdx))
        }, null, 8, ["tooltip", "route", "image", "onDownload"]))
      }), 128))
    ])
  ]))
}
}

})