import { QImg as _component_q_img } from 'quasar/src/index.dev.js';
import { QBtn as _component_q_btn } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "meme-card" }
const _hoisted_2 = {
  key: 0,
  class: "label"
}

import { RouteLocationRaw } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'GridCard',
  props: {
    label: { default: '' },
    tooltip: {},
    route: {},
    image: {},
    backgroundColor: { default: 'none' },
    aspectRatio: { default: 1 },
    showDownload: { type: Boolean, default: true }
  },
  emits: ["download"],
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  
  const _component_router_link = _resolveComponent("router-link")!
  

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: _ctx.route,
      title: _ctx.tooltip
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_img, {
          src: _ctx.image,
          ratio: _ctx.aspectRatio,
          "data-label": _ctx.label,
          style: _normalizeStyle({
                    background: _ctx.backgroundColor,
                })
        }, null, 8, ["src", "ratio", "data-label", "style"]),
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["to", "title"]),
    (_ctx.showDownload)
      ? (_openBlock(), _createBlock(_component_q_btn, {
          key: 0,
          class: "download-btn",
          color: "positive",
          "text-color": "white",
          round: "",
          icon: "get_app",
          title: "Download Template",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('download')), ["prevent"]))
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})